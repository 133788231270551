import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaRegUser } from "react-icons/fa";
const popins = Poppins({ weight: "400", subsets: ["latin"] });
const quickSand = Quicksand({ weight: "400", subsets: ["latin"] });



export default function TestimonialSlide({}) {

  const TestiData =[  
    {
      img:"/testimonial-img/alex.webp",
      review:"I had completed AWS course.I learn lot of new knowledge not only for AWS but also other from Ghani Sir.Thank for excellent training given by you",
      UserName: "Jaaeic Sseak",
      Course: "AWS DevOps",
    },
    
    {
      img:"/testimonial-img/alex.webp",
      review:"I have completed my full stack development course in learnsoft.org institute. They are very helpful for us to gain technical knowledge.and they are providing internship it is very helpful to improve our skills",
      UserName: "Mohamed",
      Course: "Full Stack Development",
    },
      
    {
      img:"/testimonial-img/alex.webp",
      review:"I am from pune. On first I was afraid to learn the course in online. later I had a demo session on Azure. From the demo to till my whole course completion I had an amazing experience thanks to ghani",
      UserName: "Nishanthini Praveena",
      Course: "Azure DevOps",
    },
    {
      img:"/testimonial-img/alex.webp",
      review:"I had completed  flutter course at Learnsoft academy. The training was so good and they taught me well and gave a lot of career guidance, Thank you team.",
      UserName: "Pavi Sparrow",
      Course: "Flutter",
    },
    {
      img:"/testimonial-img/alex.webp",
      review:
        "Overall PP training was good.Trainer is knowledgeable and we now have recorded sessions available too.Trainer has provided some good real time examples related to IT environment which helped me too.",
      UserName: "Bala Ganesh",
      Course: "Power Platform",
    }, 
    {
      img:"/testimonial-img/alex.webp",
      review:
        "Institute environment is very well and Trainer knowledge is excellent and with this course i got useful knowledge.",
      UserName: "Priya",
      Course: "Java",
    },   
]

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  infinite: true,
  slidesToScroll: 1,
  arrows:false,
  customPaging: (i) => (
    <div className="slick-dot" key={i}>
      <style jsx>
        {`
          .slick-dot {
            width: 10px;
            height: 10px;
            background-color: #ffa900; /* Default color */
            border-radius: 50%;
            margin: 1px 5px;
            cursor: pointer;
            display: inline-block;
            margin-bottom:-3px;
          }
          .slick-dot.slick-active {
            background-color: #ffffff; /* Set the color for the active dot */
          }
          @media only screen and (max-width: 1023px){
            .slick-dot {
              width: 10px;
              height: 10px;
              background-color: #ffa900; /* Default color */
              border-radius: 50%;
              margin: 1px 5px;
              cursor: pointer;
              display: inline-block;
              margin-bottom:30px;
            }
          }
        `}
      </style>
    </div>
  ), 
   
  responsive: [
    {
      breakpoint: 1223,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    },
    
  ]
}; 


  return (
    <>
<section className='testi-bg'> 
<div className='container'>
<div className="df fjc fac pdt42 mpdt20 fdc">
            <div className="top-cate df fac fjse ">
              <Image
                className="img pdt3"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
              <h4 className={`fs-33 mfs20  ${popins.className}`}>Testimonials</h4>
              <Image
                className="img pdt3"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
           </div>
           <h4 className={`fs-30 mfs-16 df fjc pdt30 mpdt10 ${popins.className}`}>
              What our happy students say Everyone 
            </h4>
 </div>

{/* Box */}
<Slider {...settings}>


 {TestiData.map((items,index)=>{
        return(
          <div className='col mt92 mmt50' key={index}>
          <div className='df fjse'>
        <div className='testi-box pos-r ' >
<div className='img '>
{/* <Image src={items.img} alt='User' width={"80"} height={"80"} loading='lazy'/> */}
{/* <FontAwesomeIcon icon={faUser} size="3x" /> */}
<FaRegUser className='pd8 cw' size={30}/>
</div>
<div className='df fjc pd20 pdb0 fdc'>
<p className={`fs-14 dim-clr mfs-10 hvz ${quickSand.className}`}>{items.review}</p>

<div className='df fjc fac'>
<p className="line-code mt16 mmt11"></p>
</div>

</div>
<div className='pdl32 mt16 df fjsb mmt12 hvz'>
  <div>
  <p className={`fs-16 mfs-12 ${quickSand.className}`}>{items.UserName}</p>
  <p className={`fs-12 dim-clr mt4 mfs-9 mmt2`}>{items.Course}</p>
  </div>
  <div className='df fac pdr30'>
    <Image src={"/testimonial-img/rating-star.webp"} alt='Rating' width={"88"} height={"16"} loading='lazy'/>
  </div>
</div>
</div>


</div></div> )
      })}</Slider></div> 
</section>
<style jsx>
{`
.testi-box:hover {
  background-color: #ffa900;
  transition: all 0.5s ease-in-out;
}
.testi-box:hover .hvz {
  color: #ffffff;
}

    .col {
      width: 100%; // Set the width to 100% for smaller screens
      margin-bottom: 52px;
      margin-left: auto;
      margin-right: auto; // Center the slider on the screen
    }

.testi-bg{

background-color: #FEEAC3;
width: 100%;
height: 700px;
overflow: hidden;

}
.testi-box{
    width: 90%;
    height: 220px;
    border-radius: 16px;
    background-color: #FFFFFF;
    cursor: pointer;
    margin:0px 15px;
    padding:5px;
}
.top-cate {
    background-color: #ffffff;
    width: 330px;
    height: 70px;
    border: 1px solid #fdbb39;
    border-radius: 46px;
    box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
  }
  .img{
    position: absolute;
    top:-38px;
    background-color: #FFA900;
    left:16px;
    border-radius: 50px;
    width: 50px;
    height:50px;
  }
  .line-code {
    width: 336px;
    border: 1px solid #d3d3d3;
    background-color: #d3d3d3;
  }
@media only screen and (max-width: 1023px){
    .testi-bg{
        background-color: #FEEAC3;
        width: 100%;
        height: 450px;        
        }
        .testi-box{
            width: 280px;
            height: 190.62px;
            border-radius: 12.2px;
            background-color: #FFFFFF;
        }
        .img{
            position: absolute;
            top:-38px;
          }
          .line-code {
            width: 256px;
            border: 1px solid #d3d3d3;
            background-color: #d3d3d3;
          }
          .top-cate {
            background-color: #ffffff;
            width: 230px;
            height: 44px;
            border: 1px solid #fdbb39;
            border-radius: 46px;
            box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
          }
}
`}

</style>

</>
  )
}
