import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image'
import React from 'react'
const popins = Poppins({ weight: "400", subsets: ["latin"] });
const quickSand = Quicksand({ weight: "400", subsets: ["latin"] });
export default function Template() {
  return (
    <section className='mmb20'>

<div className='container'>
<div className="df fjc fac mt48">
            <div className="top-cate df fac fjsa">
              <Image
                className="img pdt3"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
              <h4 className={`fs-33 mfs-20 ${popins.className}`}>We are Awesome</h4>
              <Image
                className="img pdt3"
                src={"/home/round-img.webp"}
                alt="Circle-Img"
                width={"20"}
                height={"20"}
                loading="lazy"
              />
            </div>
          </div>
          <h4 className={`fs-45 mfs-16 df fjc pdt32 mpdt19 ${popins.className}`}>
          World Class Education for <span className={`${popins.className} vec-bg-1`}> Everyone </span>
        </h4>
    {/* template 1 */}
    <div className='df   mfdcr mfac mjc mt58 mmt32'>
        <div className='df fdc fjc mmt32'>
    <div className='mdn'>
        <Image src={"/template-img/user-img.webp"} alt='User-Icon' width={"120"} height={"120"} loading='lazy'/>
    </div>
    <div className='dn mdb'>
        <Image src={"/template-img/user-img.webp"} alt='User-Icon' width={"68"} height={"68"} loading='lazy'/>
    </div>
    <div className='mt48 mmt32'>
              <h1 className={`fs-33 mfs-16  ${popins.className}`}>
              Best Software Training Institute  
                </h1>
                <p className={`fs-16 mfs-12 mt24 mmt16  ${quickSand.className}`}>
                learnsoft.org is the best training institute and stands out as a beacon in the field of teaching. We are renowned for our comprehensive and industry-relevant training programs. With a team of experienced and certified instructors, we can provide top-notch guidance for selecting the right courses for your future.  
                </p>
                </div> 
                </div>       
                <div className='mdn pdl20'>
                    <Image src={"/template-img/teach-img.webp"} alt='Teach-Img' width={"720"} height={"398"} loading='lazy'/>
                </div>
                <div className='dn mdb'>
                    <Image src={"/template-img/teach-img.webp"} alt='Teach-Img' width={"280"} height={"194"} loading='lazy'/>
                </div>

    </div>

    {/* template 2 */}

    <div className='df   mfdc mfac mjc mt100 mmt32'>
    <div className='mdn'>
                    <Image src={"/template-img/guide-img.webp"} alt='Guide-Img' width={"720"} height={"398"} loading='lazy'/>
                </div>
                <div className='dn mdb '>
                    <Image src={"/template-img/guide-img.webp"} alt='Guide-Img' width={"280"} height={"185"} loading='lazy'/>
                </div>
        <div className='df fdc fjc mmt32 pdl20 mpdl0'>
    <div className='mdn'>
        <Image src={"/template-img/inbox-img.webp"} alt='Inbox-Icon' width={"120"} height={"120"} loading='lazy'/>
    </div>
    <div className='dn mdb'>
        <Image src={"/template-img/inbox-img.webp"} alt='Inbox-Icon' width={"68"} height={"68"} loading='lazy'/>
    </div>
    <div className='mt48 mmt32'>
              <h4 className={`fs-33 mfs-16  ${popins.className}`}>
              Tailor made Courses </h4>
                <p className={` fs-16 mfs-12 mt24 mmt16 ${quickSand.className}`}>
                learnsoft.org boasts a team of experienced and knowledgeable instructors who are passionate about imparting their expertise to the next generation of professionals. The course is carefully designed to align with industry standards, ensuring that students graduate not only with theoretical knowledge but also with practical, hands-on experience.  
                </p>
                </div> 
                </div>       
              

    </div>

    {/* template 3 */}

    <div className='df   mfdcr mfac mjc mt100 mmt32'>
        <div className='df fdc fjc mmt32'>
    <div className='mdn'>
        <Image src={"/template-img/circle-img.webp"} alt='Circle-Icon' width={"120"} height={"120"} loading='lazy'/>
    </div>
    <div className='dn mdb'>
        <Image src={"/template-img/circle-img.webp"} alt='Circle-Icon' width={"68"} height={"68"} loading='lazy'/>
    </div>
    <div className='mt48 mmt32'>
              <h4 className={`fs-33 mfs-16  ${popins.className}`}>
              Crafting Your Career Launch Plan                </h4>
                <p className={`fs-16 mfs-12 mt24 mmt16  ${quickSand.className}`}>
                Begin your path to IT success with our dedicated team and comprehensive services. From resume prep to real-time projects, we're here to empower you every step. Let us help you turn aspirations into achievements and reach your full potential in IT.</p>
                </div> 
                </div>       
                <div className='mdn pdl20 pdt30'>
                    <Image src={"/template-img/job-img.webp"} alt='Teach-Img' width={"650"} height={"528"} loading='lazy'/>
                </div>
                <div className='dn mdb '>
                    <Image src={"/template-img/job-img.webp"} alt='Teach-Img' width={"280"} height={"227"} loading='lazy'/>
                </div>

    </div>
</div>
<style jsx>
{`
.top-cate {
    background-color: #ffffff;
    width: 410px;
    height: 70px;
    border: 1px solid #fdbb39;
    border-radius: 46px;
    box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
  }
  .vec-bg-1 {
    background-image: url("/template-img/every-vec-bg.webp");
    display: inline-block;
    width: 217px;
    height: 64px;
    text-align: center;
  }
@media only screen and (max-width:1023px){
  .vec-bg-1 {
    background-image: url("/template-img/every-vec-bg-mop.webp");
    display: inline-block;
    width: 77.58px;
    height: 22.88px;
    text-align: center;
    background-repeat: no-repeat;
    padding: 1px;
  }
    .top-cate {
        background-color: #ffffff;
        width: 280px;
        height: 44px;
        border: 1px solid #fdbb39;
        border-radius: 46px;
        box-shadow: 0 8px 46px 0 rgba(253, 187, 57, 0.3);
      }
   }
`}
</style>
</section>
  )
}
