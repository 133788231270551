import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import React from 'react'
const quickSand = Quicksand({ weight: "500", subsets: ["latin"] });
const popins = Poppins({ weight: "400", subsets: ["latin"] });
const extrapopins = Poppins({ weight: "500", subsets: ["latin"] });

export default function AchiveBox() {
  return (
<>

<section>

<div className='container'>

<div className='df fdc fac fjc mt48 mmt24'>

<h4 className={`fs-33 mfs-16 ${popins.className}`}>Achieve Your Goal with<span className={`learn-vector pd10 mpd5`}> learnsoft.org </span></h4>

<p className={`achiv-para ${quickSand.className}`}>learnsoft.org institute serves as a hub for learning, innovation, and professional development in the field of IT, offering aspiring individuals the knowledge and skills needed to excel in this specialized field of IT.</p>
</div>

   <div className='df fac fjc fjsa mfw mpdt20'>
    
   <div className='course-box pos-r'>

<h6 className={`pdt24 pdl24 mpdl18 mpdt15  fs-19 mfs-14 ${extrapopins.className}`}>Expert Instructor</h6>
<p className={`pdt10 mpdt5 para-box-cnt ${quickSand.className}` }>
Our trainers are a group of acclaimed industry experts from different domains. They can assist with real time scenarios, projects and guide you through the placements. </p>

{/* <div className='instructor-bg'>
</div> */}
 <div className='instructor-img mdn '>
  <Image className='' src={"/achiv-img/instruct-img.webp"} alt={"instructor-Image"} width={"196"} height={"227"} loading='lazy'/></div>
 <div className='instructor-img dn mdb '>

   <Image className='' src={"/achiv-img/instruct-img.webp"} alt={"instructor-Image"} width={"149"} height={"180"} loading='lazy'/></div>

</div>

<div className='course-box pos-r 'style={{backgroundColor:"#3D54CE"}}>

<h6 className={`pdt24 pdl24 mpdl18 mpdt15  fs-19 mfs-14 ${extrapopins.className}`}>Specialized courses </h6>
<p className={`pdt10 mpdt5 para-box-cnt ${quickSand.className}` }>
Our expert instructors meticulously craft courses infused with vital skills and the latest technology for guaranteed software success. Become a future tech guru with us!</p>

{/* <div className='course-bg' >
</div> */}
 <div className='course-img mdn '>
  <Image className='' src={"/achiv-img/course-imge.webp"} alt={"instructor-Image"} width={"310"} height={"230"} loading='lazy'/></div>
 <div className='course-img dn mdb '>

   <Image className='' src={"/achiv-img/course-imge.webp"} alt={"instructor-Image"} width={"250"} height={"170"} loading='lazy'/></div>

</div>
<div className='course-box pos-r' style={{backgroundColor:"#FFA900"}}>

<h6 className={`pdt24 pdl24 mpdl18 mpdt15 fs-19 mfs-14 ${extrapopins.className}`}>Placement Assistance</h6>
<p className={`pdt10 mpdt5 para-box-cnt ${quickSand.className}` }>

Get personalized support to enhance your job search and unlock career opportunities. With expert guidance, confidently navigate the job market and elevate your career prospects.
</p>
{/* <div className='instructor-bg'>
</div> */}
 <div className='certficate-img mdn '>
  <Image className='' src={"/achiv-img/certfct-img.webp"} alt={"instructor-Image"} width={"226"} height={"227"} loading='lazy'/></div>
 <div className='certficate-img dn mdb '>

   <Image className='' src={"/achiv-img/certfct-img.webp"} alt={"instructor-Image"} width={"170"} height={"150"} loading='lazy'/></div>

</div>
    
    </div>  

</div>


</section>


<style jsx>
    {`
      .instructor-img {
        position: absolute;
        bottom: 0px;
        right: 20px;
      }
      .certficate-img {
        position: absolute;
        bottom: -15px;
        right: -13px;
      }
 .course-box {
  width: 400px;
  height: 256px;
  margin-top: 40px;
  background-color: #ffa900;
  border-radius: 16px;
  overflow:hidden;
}


.course-img {
  position: absolute;
  bottom: -31px;
  right: -30px;
  transform: rotate(-5deg);
}
       .para-box-cnt {
        width: 178px;
        height: 132px;
        color: #ffffff;
        margin-left: 24px;
        line-height: 22px;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.25px;
      }
    .achiv-para {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5px;
      color: #000000;
      width: 1000px;
      height: 72px;
    }
    .learn-vector {
      background-image: url("/achiv-img/learning-vector-img.webp");
      width: 217px;
      display: inline-block;
      height: 64px;
      background-repeat: no-repeat;
    }
      @media only screen and (max-width: 1250px){
      
      .mfw{
      flex-wrap: wrap !important;
      }

      }

    @media only screen and (max-width: 1023px){

      .learn-vector {
        background-image: url("/achiv-img/mb-learning-vector-img.webp");
        width: 97.37px;
        display: inline-block;
        height: 25.56px;
        background-repeat: no-repeat;
      }
  
      .achiv-para {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #000000;
        width: 100%;
        height:28.86px;
        padding-bottom:8px;
      }
      
      .course-box {
        width: 312px;
        height: 199.68px;
        margin-top: 24.16px;
        background-color: #ffa900;
        border-radius: 12.48px;
      }
      .course-img {
        position: absolute;
        bottom: -17px;
        right: -30px;
        transform: rotate(-5deg);
      }
      .certficate-img {
        position: absolute;
        bottom: -15px;
        right: 0px;
      }

    
      .para-box-cnt {
        width: 139px;
        height: 103px;
        color: #ffffff;
        margin-left: 18.72px;
        line-height: 17.16px;
        font-weight: 400;
        font-size: 10.92px;
        letter-spacing: 0.25px;
      }

    }
    
    `}
</style>

</>
  )
}
